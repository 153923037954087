import * as React from 'react';
import clsx from 'clsx';
import { Header } from '../../Header/Header';
import { Footer } from '../../Footer/Footer';
import 'swiper/swiper.min.css';
import * as scss from './PageLayout.module.scss';

export type PageLayoutProps = {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const PageLayout = ({ children, className, style }: PageLayoutProps) => {
  const isBrowser = typeof window !== 'undefined';

  // GA_TAG_EVENT
  if (isBrowser) {
    window.dataLayer.push({
      event: 'pageview',
    });
  }

  return (
    <>
      <Header />

      <main className={clsx(scss.main, className)} style={style}>
        {children}
      </main>

      <Footer />
    </>
  );
};
