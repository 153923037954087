// extracted by mini-css-extract-plugin
export var brand = "Menu-module--brand--3za5H";
export var brandLogo = "Menu-module--brand-logo--hrchS";
export var brandPartnerLogo = "Menu-module--brand-partner-logo--qkrE0";
export var brandPartnerWith = "Menu-module--brand-partner-with--6mz7E";
export var container = "Menu-module--container--Q5q+g";
export var navItem = "Menu-module--nav-item--KKC04";
export var navItemActive = "Menu-module--nav-item-active--l8kcL";
export var navLink = "Menu-module--nav-link--HQfSG";
export var navList = "Menu-module--nav-list--UW+jF";
export var navMobile = "Menu-module--nav-mobile--sQFhJ";
export var navToggle = "Menu-module--nav-toggle--x0q+2";
export var navToggleIcon = "Menu-module--nav-toggle-icon--PdeyL";
export var navToggleSubnav = "Menu-module--nav-toggle-subnav--jhBPy";
export var navToggleSubnavIcon = "Menu-module--nav-toggle-subnav-icon--kQ9Ww";
export var navWrapper = "Menu-module--nav-wrapper--wY1AJ";
export var row = "Menu-module--row--FXDfS";
export var special = "Menu-module--special--KKM6L";
export var wrapper = "Menu-module--wrapper--SZzuX";