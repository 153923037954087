import * as React from 'react';
import clsx from 'clsx';
import * as style from './hamburger.module.scss';

export type Props = {
  isActive?: boolean;
  isDarkMode?: boolean;
  handleClick?: () => void;
};

export const Hamburger: React.FC<Props> = ({
  isActive = false,
  isDarkMode = false,
  handleClick,
}) => {
  return (
    <button
      className={clsx(
        style.hamburger,
        { 'is-active': isActive },
        { 'is-dark-mode': isDarkMode }
      )}
      type="button"
      onClick={handleClick}>
      <span className={clsx(style.hamburgerBox)}>
        <span className={clsx(style.hamburgerInner)}></span>
      </span>
    </button>
  );
};
