import * as React from 'react';
import clsx from 'clsx';
import * as style from './Header.module.scss';
import { Menu } from '../Menu/Menu';
import * as data from '../Menu/data';

export const Header: React.FC = ({ children }) => {
  return (
    <header className={clsx(style.wrapper)}>
      <Menu
        withPartner={data.withPartner}
        partnerLogo={data.partnerLogo}
        partnerName={data.partnerName}
        menu={data.menu}
      />
      {children}
    </header>
  );
};
