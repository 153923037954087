import * as React from 'react';
import clsx from 'clsx';
import * as style from './Menu.module.scss';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  isHamburger: boolean;
};

export const MenuList = ({ children, className, isHamburger }: Props) => {
  return (
    <ul
      className={clsx(style.navList, className)}
      style={isHamburger ? { flexDirection: 'column' } : {}}>
      {children}
    </ul>
  );
};
