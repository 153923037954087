import * as React from 'react';
import { LinkGetProps } from '@reach/router';
import clsx from 'clsx';
import { Link } from 'gatsby';
import * as style from './Menu.module.scss';
import { useLocation } from '@reach/router';

const classShowSubmenu = 'show-submenu';
const classHasSubmenu = 'has-submenu';
const classCurrentItem = 'is-current';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  hasSubmenu?: boolean;
  label?: string;
  to: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  special?: boolean;
};

export const MenuItem = ({
  children,
  className,
  hasSubmenu = false,
  label,
  to,
  special,
  onClick,
}: Props) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const timerId = React.useRef<number>();
  const ref = React.useRef<HTMLLIElement>(null);

  const isActive = ({ href, location, isCurrent }: LinkGetProps) => {
    const isCurrentSubmenu = !!location.hash && href.search(location.hash) > -1;

    return {
      className: clsx(style.navLink, {
        [classCurrentItem]: isCurrent || isCurrentSubmenu,
      }),
    };
  };

  const onMouseLeave = () => {
    ref.current?.classList.remove(classShowSubmenu);
    window.clearTimeout(timerId.current);
  };

  const onMouseEnter = () => {
    timerId.current = window.setTimeout(() => {
      ref.current?.classList.add(classShowSubmenu);
    }, 200);
  };

  // UNCOMMENT TO HIDE SPANISH MENU
  // OR COMMENT OUT TO SHOW SPANISH MENU
  // ... UNLESS URL HAS ?showspanish=1 AT THE END
  // if (search.get('showspanish') !== '1' && to === '/spanish') return null;

  return (
    <li
      ref={ref}
      className={clsx(
        style.navItem,
        { [classHasSubmenu]: hasSubmenu },
        className,
        special && style.special
      )}
      onMouseLeave={onMouseLeave}>
      <Link
        to={to}
        activeClassName={classCurrentItem}
        className={clsx(style.navLink)}
        getProps={isActive}
        onClick={onClick}
        onMouseEnter={onMouseEnter}>
        {label}
      </Link>
      {children}
    </li>
  );
};
